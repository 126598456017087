<template>
  <div class="app-box">
    <van-nav-bar v-if="navTop" left-arrow @click-left="onClickLeft" />
    <div class="borapm">
      <el-steps
        :active="active"
        finish-status="wait"
        process-status="finish"
        align-center
        v-if="active == 5"
      >
        <el-step title="已取消" color="red">
          <template #icon>
            <div v-if="active == 0" class="baolee">1</div>
            <div v-else>1</div>
          </template>
        </el-step>
      </el-steps>

      <el-steps
        :active="active"
        finish-status="wait"
        process-status="finish"
        align-center
        v-else-if="active == 6"
      >
        <el-step title="已退款" color="red">
          <template #icon>
            <div v-if="active == 0" class="baolee">1</div>
            <div v-else>1</div>
          </template>
        </el-step>
      </el-steps>

      <el-steps
        :active="active"
        finish-status="wait"
        process-status="finish"
        align-center
        v-if="active == 7"
      >
        <el-step title="已驳回" color="red">
          <template #icon>
            <div v-if="active == 0" class="baolee">1</div>
            <div v-else>1</div>
          </template>
        </el-step>
      </el-steps>

      <el-steps
        :active="active"
        finish-status="wait"
        process-status="finish"
        align-center
        v-else
      >
        <el-step title="待付款">
          <template #icon>
            <div v-if="active == 0" class="baolee">1</div>
            <div v-else>1</div>
          </template>
        </el-step>
        <el-step title="已付款">
          <template #icon>
            <div v-if="active == 1" class="baolee">2</div>
            <div v-else>2</div>
          </template>
        </el-step>
        <el-step title="待发货">
          <template #icon>
            <div v-if="active == 2" class="baolee">3</div>
            <div v-else>3</div>
          </template>
        </el-step>
        <el-step title="已发货">
          <template #icon>
            <div v-if="active == 3" class="baolee">4</div>
            <div v-else>4</div>
          </template>
        </el-step>
        <el-step title="已完成">
          <template #icon>
            <div v-if="active == 4" class="baolee">5</div>
            <div v-else>5</div>
          </template>
        </el-step>
      </el-steps>
    </div>

    <!-- end -->
    <div class="centAuot">
      <div class="left">
        <img src="../../assets/img/home_logistics_icon.png" alt="" />
      </div>
      <!-- <div class="right">
        <p class="sopefftitl">物品已发货，实时更新</p>
        <p class="sopeff">2021-08-18 已发送到某某地区</p>
      </div> -->
      <div class="right" v-if="active == 0">
        <!-- <p  class="sopefftitl sopefftitl1"></p> -->
      </div>
      <div class="right" v-else-if="active == 1">
        <p class="sopefftitl sopefftitl1">还在审核中，请耐心等待！</p>
      </div>
      <div class="right" v-else-if="active == 2">
        <p class="sopefftitl sopefftitl1">还在审核中，请耐心等待！</p>
      </div>
      <div class="right" v-else-if="active == 3">
        <p class="sopefftitl">物品已发货，实时更新</p>
        <p class="sopeff">2021-08-18 已发送到某某地区</p>
      </div>
      <div class="right" v-else-if="active == 4">
        <p class="sopefftitl sopefftitl1">已完成</p>
      </div>
    </div>
    <!-- end -->
    <div class="centAuot">
      <p class="centText">
        运营签约信息表 <span class="rightxope">合同文件下载</span>
      </p>
      <!-- end -->
      <div class="TopBox">
        <div class="left">
          <img
            v-if="listArr.op_role == 3"
            src="../../assets/img/yyzx.png"
            alt=""
          />
          <img
            v-if="listArr.op_role == 8"
            src="../../assets/img/operateOper.png"
            alt=""
          />
        </div>
        <div class="right">
          <p class="rightText">
            <span class="spanColor">签约城市:</span
            >{{
              listArr.province == null
                ? "全国"
                : listArr.province + listArr.city + listArr.area
            }}
          </p>
          <p class="rightText">
            <span class="spanColor">合同编号:</span>{{ listArr.sn_code }}
          </p>
          <p class="rightText_p">
            <span class="spanColor">{{ listArr.truename }}:</span
            >{{ listArr.phone }}
          </p>
        </div>
        <div class="mboeBox mboeBoxBoder">
          <div class="leftbox">身份证号:</div>
          <div class="rightbox">{{ listArr.id_card }}</div>
        </div>
        <div class="mboeBox" v-if="listArr.address">
          <div class="leftbox">合同配送：</div>
          <div class="rightbox">
            {{ listArr.address }}
          </div>
        </div>

        <p class="titleirmb">
          订单金额:<span class="rmbColor">¥{{ listArr.price }}</span>
        </p>
        <div
          class="tiexjai"
          v-if="listArr.address == '' || listArr.address == null"
        >
          <van-button type="info" block @click="jimeorib"
            >填写运营入驻信息表</van-button
          >
        </div>
      </div>
    </div>
    <!-- end -->
    <div class="centAuot">
      <p class="centText">订单信息</p>
      <div class="mboeBox">
        <div class="leftbox">创建时间：</div>
        <div class="rightbox">{{ listArr.create_date }}</div>
      </div>

      <div class="mboeBox">
        <div class="leftbox">订单编号：</div>
        <div class="rightbox">
          {{ listArr.sn_code
          }}<span class="rightCope" @click="copyUrl(listArr.sn_code)"
            >复制</span
          >
        </div>
      </div>

      <div class="mboeBox" v-if="listArr.express_sn">
        <div class="leftbox">运单号码：</div>
        <div class="rightbox">
          {{ listArr.express_sn
          }}<span class="rightCope" @click="copyUrl(listArr.express_sn)"
            >复制</span
          >
        </div>
      </div>
      <div class="ulBoxImg">
        <div
          class="liImg"
          v-for="(item, index) in listArr.payment_voucher"
          :key="index"
        >
          <img :src="item" alt="" @click="ImgEnlarge(index)" />
        </div>
        <!-- <div class="liImg">
          <img src="../../assets/img/imgpyq.jpg" alt="" />
        </div>
        <div class="liImg">
          <img src="../../assets/img/imgpyq.jpg" alt="" />
        </div> -->
      </div>
    </div>
    <!-- end去付款 -->
    <template v-if="active == 0">
      <div class="positFlex">
        <div class="liFlex">
          <p>
            合计:<span class="colornkae">¥{{ listArr.price }}</span>
          </p>
        </div>
        <div class="liFlex1" @click="nextClick">去上传凭证</div>
      </div>
    </template>
    <Loding ref="loding" @load="load"></Loding>
  </div>
</template>

<script>
import { getOrdersInfo } from "@/request/api";
import { Toast } from "vant";
import { ImagePreview } from "vant";
import Loding from "@/components/Loding/Loding";
import moment from "moment";
export default {
  components: {
    Loding,
  },
  data() {
    return {
      active: 10,
      listArr: [],
      navTop: true,
    };
  },
  mounted() {
    this.getInfo();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      console.log(vm.navTop);
      if (from.fullPath == "/order") {
        vm.navTop = false;
        next();
      }
    });
  },
  methods: {
    //过滤时间
    timeDiff(timefor) {
      let diff = moment().diff(moment(timefor));
      if (diff >= 1800000) {
        return false;
      } else {
        // this.orderType = true;
        let dataTime = 1800000 - diff;
        return dataTime;
      }
    },
    nextClick() {
      if (this.listArr.op_role == 3) {
        this.$router.push({
          path: "/voucher",
          query: { id: this.$route.query.id },
        });
      } else if (this.listArr.op_role == 8) {
        this.$router.push({
          path: "/voucherOper",
          query: { id: this.$route.query.id },
        });
      }
    },
    onClickLeft() {
      this.$router.push("/user");
    },
    load() {
      this.$refs.loding.loding = true;
      this.$refs.loding.loderror = false;
      this.getInfo();
    },
    ImgEnlarge(index) {
      ImagePreview({
        images: this.listArr.payment_voucher,
        startPosition: index,
      });
    },
    getInfo() {
      let data = {
        id: this.$route.query.id,
      };
      getOrdersInfo(data)
        .then((res) => {
          this.listArr = res.data;
          //时间处理

          // 状态处理
          if (res.data.state == "4") {
            //待付款
            this.active = 0;
          } else if (res.data.state == "3") {
            //已付款
            this.active = 1;
          } else if (res.data.state == "8") {
            //待发货
            this.active = 2;
          } else if (res.data.state == "7") {
            //已发货
            this.active = 3;
          } else if (res.data.state == "1") {
            //已完成
            this.active = 4;
          } else if (res.data.state == "5") {
            //取消付款
            this.active = 5;
          } else if (res.data.state == "6") {
            //已退款
            this.active = 6;
          } else if (res.data.state == "2") {
            //已驳回
            this.active = 7;
          }
          this.$refs.loding.loding = false;
        })
        .catch((error) => {
          this.$refs.loding.loderror = true;
          this.$refs.loding.loding = false;
        });
    },
    copyUrl(url) {
      var domUrl = document.createElement("input");
      domUrl.value = url;
      domUrl.id = "creatDom";
      document.body.appendChild(domUrl);
      domUrl.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      let creatDom = document.getElementById("creatDom");
      creatDom.parentNode.removeChild(creatDom);
      Toast.success("复制成功");
    },
    jimeorib() {
      if (this.listArr.op_role == 3) {
        this.$router.push({
          path: "/SettledinformationTex",
          query: { id: this.$route.query.id },
        });
      } else if (this.listArr.op_role == 8) {
        this.$router.push({
          path: "/SettledinformationOper",
          query: { id: this.$route.query.id },
        });
      }
    },
  },
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background-color: #edf2f5ff;
  box-sizing: border-box;
  overflow: hidden;
}
.borapm {
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
}
.borapm /deep/.el-step__icon {
  background: #edf2f5ff;
}

.baolee {
  background: #0e8affff;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  color: #fff;
  position: relative;
}
.baolee::before {
  content: "";
  position: absolute;
  width: 0.7rem;
  height: 1px;
  background: #0e8affff;
  left: 0.5rem;
  top: 50%;
  transform: translate(0, -50%);
}
.baolee::after {
  content: "";
  position: absolute;
  width: 0.7rem;
  height: 1px;
  background: #0e8affff;
  right: 0.5rem;
  top: 50%;
  transform: translate(0, -50%);
}
.borapm /deep/.el-step__title {
  font-size: 0.37rem;
}
.centAuot {
  background: #fff;
  padding: 0.2rem 0.3rem;
  overflow: hidden;
  margin-bottom: 5px;
}
.centAuot .left {
  float: left;
}
.centAuot .left img {
  width: 1.3rem;
}
.centAuot .right {
  margin-left: 1.5rem;
  font-size: 0.48rem;
}
.centAuot .centText {
  font-size: 0.42rem;
  font-weight: bold;
  position: relative;
  padding-left: 10px;
}
.centAuot .centText::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 15px;
  background: #0e8affff;
  border-radius: 10px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}
.rightxope {
  float: right;
  background: rgba(14, 138, 255, 0.1);
  color: #0e8affff;
  padding: 3px 5px;
  border-radius: 5px;
  font-size: 0.43rem;
  font-weight: 400;
}

.coloeTxt {
  color: #0e8affff;
  font-size: 0.43rem;
  font-weight: bold;
  text-align: center;
  padding: 0.4rem 0;
  padding-bottom: 0.2rem;
}
.coloeTxt .icon-shijian {
  margin-right: 0.1rem;
  font-size: 0.5rem;
}
.timeBox {
  background: #0e8affff;
  display: inline-block;
  padding: 0.2rem 0.2rem;
  border-radius: 10px;
}
.timeUl {
  text-align: center;
  margin-top: 0.1rem;
}
.timeUl ::v-deep.van-count-down {
  display: inline-block;
}
.iconbsn {
  display: inline-block;
  background: RGBA(214, 231, 246, 1);
  color: #0e8aff;
  padding: 0.1rem 0.2rem;
  border-radius: 10px;
  font-size: 0.43rem;
}
.coloerihe {
  color: #0e8aff;
}
.UlBox {
  width: 100%;
  background: #fff;
  padding: 0.2rem 0.3rem;
  box-sizing: border-box;
  border-bottom: 5px solid #edf2f5ff;
}
.titleH3 {
  font-size: 0.43rem;
  position: relative;
  padding-left: 10px;
  font-weight: bold;
}
.titleH3::before {
  content: "";
  display: inline-block;
  width: 5px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  background: #0e8aff;
  border-radius: 10px;
}
.li .pTitle {
  color: #333b5c;
  font-size: 0.4rem;
  line-height: 1rem;
}
.li .HTitle {
  font-size: 0.43rem;
  color: #000;
  padding-right: 2rem;
}
.mapeBox {
  border-bottom: 1px solid #fff3f3f5;
  padding: 0.3rem 0;
  position: relative;
}
.mapeBox .copy {
  position: absolute;
  right: 0;
  bottom: 0.34rem;
  font-size: 0.43rem;
  color: #0e8aff;
}
.li .mapeBox:nth-last-child(1) {
  border: none;
}
.TopBox {
  box-sizing: border-box;
  background: #fff;
  overflow: hidden;
  padding-top: 0;
  width: 100%;
  margin-top: 0.3rem;
}
.TopBox .left {
  float: left;
}
.TopBox .right {
  margin-left: 2.3rem;
  font-size: 0.4rem;
}
.TopBox .right p {
  color: #000a33ff;
}
.TopBox .left img {
  width: 2rem;
}
.right .spanColor {
  color: #333b5cff;
}
.TopBox .service {
  font-size: 0.43rem;
  color: #333b5cff;
  line-height: 1rem;
  border-bottom: 1px solid #f3f3f5ff;
}
.fr {
  float: right;
  color: #0e8aff;
}
.serviceText {
  font-size: 0.43rem;
  color: #333b5cff;
  margin-top: 0.3rem;
}
.titleBoxtxt {
  font-size: 0.4rem;
  margin-top: 0.2rem;
}
.positFlex {
  width: 100%;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  line-height: 1.5rem;
  box-sizing: border-box;
}
.positFlex .liFlex {
  flex: 4;
  font-size: 0.45rem;
}
.positFlex .liFlex p {
  padding: 0 0.3rem;
}
.positFlex .liFlex1 {
  flex: 2;
  text-align: center;
  font-size: 0.45rem;
  background: #0e8affff;
  color: #fff;
}
.colornkae {
  color: #fb4a51ff;
}
.titleH31 {
  background: #fff;
  padding: 0.3rem;
}
.titleirmb {
  text-align: right;
  background: rgba(242, 249, 255, 1);
  border-radius: 10px;
  line-height: 1.5rem;
  font-size: 0.43rem;
  padding-right: 0.5rem;
}
.rmbColor {
  color: #fb4a51ff;
}
.imgWIdhr {
  width: 80px;
  height: 80px;
  margin-right: 0.2rem;
}
.imgFor {
  position: relative;
  float: left;
  overflow: hidden;
  margin-bottom: 0.2rem;
}
.imgFor .meoae {
  position: absolute;
  top: 0;
  right: 8px;
  background: rgba(0, 0, 0, 0.7);
  border-bottom-left-radius: 5px;
}
.imgFor .meoae1 {
  font-size: 0.2rem;
  color: #fff;
}

.pokeoBox ::v-deep.van-button {
  border-radius: 10px;
}
.poperBox {
  padding: 0.5rem 0.5rem;
}
.poperBox img {
  width: 120px;
  height: 100px;
  margin: auto;
}
.sucerr {
  text-align: center;
  font-size: 0.4rem;
  color: #666c85ff;
  padding: 0.3rem 0;
}
::v-deep.van-button {
  border-radius: 10px;
}
.mboeBox {
  display: flex;
  font-size: 0.42rem;
  margin: 0.2rem 0;
}
.mboeBox .leftbox {
  flex: 1;
  color: #333b5cff;
}
.mboeBox .rightbox {
  flex: 3;
  color: #000a33ff;
}
.mboeBoxBoder {
  border-bottom: 1px solid #f3f3f5ff;
  padding: 0.3rem 0;
}
.rightCope {
  float: right;
  color: #0e8affff;
}
.ulBoxImg {
  overflow: hidden;
}
.ulBoxImg .liImg {
  float: left;
  margin-right: 0.1rem;
  margin-bottom: 0.15rem;
}
.ulBoxImg .liImg img {
  width: 3rem;
  height: 2rem;
  border-radius: 5px;
}
.ulBoxImg .liImg:nth-last-child(1) {
  margin-right: 0;
}
.sopeff {
  color: #666c85ff;
  font-size: 0.35rem;
}
.sopefftitl {
  margin: 0.1rem 0;
}
.sopefftitl1 {
  line-height: 1rem;
}
.rightText {
  margin-bottom: 0.1rem;
}
.rightText_p {
  margin-top: 0.3rem;
}
.positFlex {
  width: 100%;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  background: #fff;
  line-height: 1.5rem;
  box-sizing: border-box;
}
.positFlex .liFlex {
  flex: 4;
  font-size: 0.45rem;
}
.positFlex .liFlex p {
  padding: 0 0.3rem;
}
.positFlex .liFlex1 {
  flex: 2;
  text-align: center;
  font-size: 0.45rem;
  background: #0e8affff;
  color: #fff;
}
.overtime {
  font-size: 0.4rem;
  text-align: center;
  color: red;
  padding: 0.3rem 0;
}
.tiexjai {
  margin: 0.5rem 0;
}
</style>