import { render, staticRenderFns } from "./TypePage.vue?vue&type=template&id=3fd4943e&scoped=true&"
import script from "./TypePage.vue?vue&type=script&lang=js&"
export * from "./TypePage.vue?vue&type=script&lang=js&"
import style0 from "./TypePage.vue?vue&type=style&index=0&id=3fd4943e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fd4943e",
  null
  
)

export default component.exports